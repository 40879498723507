.messages-wrapper {
  video {
    outline: none !important; }
  .message.attach-previous {
    padding-top: 0; }
  .message.attach-next {
    padding-bottom: 3px; }
  .spacer {
    width: 60px;
    height: 60px; }
  .message {
    > .picture {
      margin-bottom: -20px; }
    > .spacer {
      margin-bottom: -20px; } }
  .chat .message .content .bubble.attach-previous.left {
    border-top-left-radius: 0; }
  .chat .message .content .bubble.attach-previous.right {
    border-top-right-radius: 0; }
  .chat .message .content .bubble.attach-next.left {
    border-bottom-left-radius: 0; }
  .chat .message .content .bubble.attach-next.right {
    border-bottom-right-radius: 0; }
  .bubble.right a {
    color: white; }
  .bubble.left a {
    color: #9fadb8; }


  .content-audio {
    width: 100%;
    height: 40px; }
  .content-download {
    display: flex;
    align-items: center; }
  .content-name {
    font-weight: bold; }
  .content-size {
    font-size: 12px; }
  .content-icon {
    min-width: 27px;
    width: 27px;
    font-size: 18px;
    margin-left: 10px; } }
